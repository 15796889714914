.message
  display: none
  position: fixed
  top: 4%
  left: 10%
  padding: 20px
  border-radius: 4px
  background-color: $color-msg-info-back
  color: $color-msg-info-text
  z-index: 2
  +shadow()
  +animation-slide-message()
  font-size: 14px

  @media(min-width: $width-mobile)
    font-size: inherit

  &--show
    display: block

  &--warning
    background-color: $color-msg-warn-back
    color: $color-msg-warn-text

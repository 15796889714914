.demo-image
  position: relative
  display: block

  &:hover
    cursor: zoom-in

  & img
    opacity: 1
    display: block
    width: 100%
    height: auto
    transition: .5s ease
    border: 1px solid $color-grey-light

  & svg
    opacity: 0
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    width: 30%
    height: 30%
    fill: $color-theme-cold
    transition: .5s ease

  &:hover img
    opacity: 0.3

  &:hover svg
    opacity: 1

.helper

  & summary
    display: flex
    padding: 20px

    &:hover
      cursor: pointer

    & .helper__help
      display: none
      margin-left: auto

      & svg
        margin: auto 0
        fill: $color-theme-mid

  &:not([open]) summary:hover .helper__help

    @media(min-width: $width-mobile)
      display: flex

  &__name
    margin-left: 10px
    font-size: 18px
    line-height: 40px
    +gradient-font()

    @media(min-width: $width-mobile)
      margin-left: 20px
      font-size: 22px

  &[open] .tooltip
    pointer-events: none

  &__updated time
    color: $color-grey

  &__category
    list-style: circle
    margin: 0
    padding: 0 0 0 20px
    color: $color-grey

    @media(min-width: $width-mobile)
      margin-bottom: 16px

    & li
      padding-left: 10px

    & li::marker
      color: $color-grey-dark

  &__category,
  &__links a,
  &__description,
  &__updated,
  &__example
    font-size: 12px

    @media(min-width: $width-mobile)
      font-size: 14px

  &__details,
  &__example
    padding: 0 20px 20px 20px

  &__details
    display: flex
    flex-direction: column

    @media(min-width: $width-mobile)
      flex-direction: row

    & > .helper__column
      width: 100%
      padding: 20px 0
      border-bottom: 2px solid $color-grey-light
      font-size: 16px

      & p
        margin: 12px 0

        &:last-child
          margin-bottom: 0

      &:first-child
        min-width: 160px
        display: flex
        flex-direction: row-reverse
        justify-content: space-between
        padding-top: 0

        @media(min-width: $width-mobile)
          display: block

      @media(min-width: $width-mobile)
        width: auto
        padding: 0
        border-bottom: none
        border-right: 2px solid $color-grey-light

        &:last-child
          border-right: none

        &:nth-child(1)
          padding-right: 20px

        &:nth-child(2)
          padding-left: 20px

  &__links
    display: flex
    flex-direction: column

    & a
      display: flex
      align-items: center
      margin-bottom: 6px

      & svg
        margin-right: 12px
        fill: $color-theme-mid
        width: 14px
        height: 14px

        @media(min-width: $width-mobile)
          width: 18px
          height: 18px

      &:last-child
        margin-right: 0

  &__header
    display: flex
    align-items: center
    margin: 0 0 12px 0
    +gradient-font()

    & .button
      margin-left: auto

    & h3
      margin: 0
      font-weight: 400
      color: $color-grey
      font-size: 16px

      @media(min-width: $width-mobile)
        font-size: 18px

  &__description code,
  &__example pre
      font-size: 10px

      @media(min-width: $width-mobile)
        font-size: 12px

  &__description code:not(.hljs)
      padding: 1px 4px
      border-radius: 4px
      border: 1px solid $color-theme-mid
      color: $color-theme-mid

  &__example

    & pre
      width: 100%
      margin: 0
      display: flex
      background-color: $color-background

      & .helper__code-lines
        padding: 10px
        display: flex
        flex-direction: column
        color: $color-theme-mid
        font-weight: 600
        text-align: center

        @media(min-width: $width-mobile)
          padding: 16px

      & code
        display: block
        width: 96%
        padding: 10px 8px
        overflow: auto

        @media(min-width: $width-mobile)
          padding: 16px 8px

      & code.hljs
        background: none

  & .demo-image
    width: 80%

    @media(min-width: $width-mobile)
      width: 70%

.slider
  display: flex
  flex-direction: column

  @media(min-width: $width-mobile)
    flex-direction: row

  & input[type="radio"]
    display: none

  &__controls
    list-style: none
    width: 100%
    margin: 0
    padding: 20px
    padding-top: 0

    @media(min-width: $width-mobile)
      width: 350px

  &__controls li
    margin-bottom: 2px

    & .button
      padding: 2px 2px 2px 8px

  &__slide
    display: none
    width: 100%
    padding: 28px
    padding-top: 0px
    text-align: left

  & input#slide-1:checked~.slider__controls label[for=slide-1],
  & input#slide-2:checked~.slider__controls label[for=slide-2],
  & input#slide-3:checked~.slider__controls label[for=slide-3],
  & input#slide-4:checked~.slider__controls label[for=slide-4],
  & input#slide-5:checked~.slider__controls label[for=slide-5],
  & input#slide-6:checked~.slider__controls label[for=slide-6],
  & input#slide-7:checked~.slider__controls label[for=slide-7],
  & input#slide-8:checked~.slider__controls label[for=slide-8],
  & input#slide-9:checked~.slider__controls label[for=slide-9],
  & input#slide-10:checked~.slider__controls label[for=slide-10]
    border: 2px solid $color-theme-mid

  & input#slide-1:checked~.slider__slide--1,
  & input#slide-2:checked~.slider__slide--2,
  & input#slide-3:checked~.slider__slide--3,
  & input#slide-4:checked~.slider__slide--4,
  & input#slide-5:checked~.slider__slide--5,
  & input#slide-6:checked~.slider__slide--6,
  & input#slide-7:checked~.slider__slide--7,
  & input#slide-8:checked~.slider__slide--8,
  & input#slide-9:checked~.slider__slide--9,
  & input#slide-10:checked~.slider__slide--10
    display: block

.layout
  position: relative
  display: flex
  flex-direction: column
  min-height: 100vh
  background-color: $color-background

  & main
    width: 90%
    margin: 60px auto 0 auto

    @media(min-width: $width-mobile)
      width: 80%

  &__columns-wrapper
    display: flex
    flex-direction: column
    margin-bottom: 60px

    @media(min-width: $width-mobile)
      flex-direction: row

  &__column
    width: 100%
    margin: 0 auto

    @media(min-width: $width-mobile)
      width: 50%
      margin: 0
      padding-right: 40px

      &:last-child
        padding-right: 0

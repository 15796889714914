.helpers
  display: flex
  flex-direction: column
  margin: 40px 0 20px 0
  padding: 0
  list-style: none

  @media(min-width: $width-mobile)
    flex-direction: row
    flex-wrap: wrap

  &__item
    align-self: flex-start
    width: 100%
    margin-bottom: 20px
    background-color: $color-paper
    border-radius: 8px
    +shadow()

    @media(min-width: $width-mobile)
      width: 49%

      &--single
        width: 100%

      &:nth-child(2n)
        margin-left: auto

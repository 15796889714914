.search
  display: flex
  flex-direction: column
  margin-bottom: 40px
  background-color: $color-paper
  border-bottom: 1px solid $color-grey-light
  border-radius: 8px
  +shadow()

  @media(min-width: $width-mobile)
    flex-direction: row

  &__input
    width: 100%
    display: flex
    margin: 0
    padding: 0
    border: none

    @media(min-width: $width-mobile)
      width: 74%

  &__type
    display: none
    padding: 0 20px
    margin-right: 10px
    border: none
    color: $color-grey-dark
    font-family: 'RobertoSans-Regular', Tahoma, sans-serif
    background-color: inherit
    font-weight: 600
    font-size: 12px

    &:hover
      cursor: pointer

    @media(min-width: $width-mobile)
      display: block

  &__query
    display: block
    width: 100%
    padding: 20px
    border: none
    color: $color-theme-mid
    background-color: inherit
    border-radius: inherit
    font-family: 'RobertoSans-Regular', Tahoma, sans-serif
    text-align: center
    font-size: 16px

    @media(min-width: $width-mobile)
      font-size: 18px
      text-align: left

    &::placeholder
      font-style: italic
      color: $color-grey-light

  &__methods
    margin: 0
    padding: 10px 10px
    color: $color-grey
    list-style: none

    @media(min-width: $width-mobile)
      width: 26%
      padding: 10px 20px

    & li
      margin-bottom: 2px
      display: flex
      justify-content: center

      @media(min-width: $width-mobile)
        justify-content: end

      & b
        color: $color-grey-dark

      & .button
        padding: 0 2px 2px 2px

@import ./consts/const.sass
@import ./mixin.sass

@font-face
  font-family: 'RobertoSans-Regular'
  font-display: swap
  src: url('./assets/fonts/RobertoSans-Regular.woff2') format('woff2')
  src: url('./assets/fonts/RobertoSans-Regular.woff') format('woff')

@font-face
  font-family: 'RobertoSans-Thin'
  font-display: swap
  src: url('./assets/fonts/RobertoSans-Thin.woff2') format('woff2')
  src: url('./assets/fonts/RobertoSans-Thin.woff') format('woff')

*
  box-sizing: border-box

html, body
  margin: 0
  font-family: 'RobertoSans-Regular', Tahoma, sans-serif
  font-weight: 400
  font-size: 22px
  line-height: normal
  color: $color-grey-dark

a
  color: $color-theme-cold
  text-decoration: none

  &:hover
    opacity: 0.8
    text-decoration: underline

section
  text-align: center
  line-height: 32px
  font-size: 14px

  @media(min-width: $width-mobile)
    font-size: 16px

.visually-hidden
  +hide()

@import ./components/Layout/Layout.sass
@import ./components/Loader/Loader.sass
@import ./components/Message/Message.sass
@import ./components/Logo/Logo.sass
@import ./components/Button/Button.sass
@import ./components/Tooltip/Tooltip.sass
@import ./components/Header/Header.sass
@import ./components/Anchor/Anchor.sass
@import ./components/Navigation/Navigation.sass
@import ./components/Categories/Categories.sass
@import ./components/SearchForm/SearchForm.sass
@import ./components/SearchMethods/SearchMethods.sass
@import ./components/Contacts/Contacts.sass
@import ./components/HelperList/HelperList.sass
@import ./components/HelperItem/HelperItem.sass
@import ./components/DemoImage/DemoImage.sass
@import ./components/Slider/Slider.sass

.button
  display: flex
  background-color: transparent
  font-family: 'RobertoSans-Regular', Tahoma, sans-serif
  color: $color-grey
  font-size: 14px

  &:active svg
    fill: $color-grey

  & svg
    align-self: center
    fill: $color-theme-mid
    width: 16px
    height: 16px

    @media(min-width: $width-mobile)
      width: 18px
      height: 18px

  @media(min-width: $width-mobile)
    font-size: 16px

  &--active
    pointer-events: none
    cursor: default

a.button
  text-decoration: none
  padding: 0 2px 2px 2px
  border-bottom: 2px solid transparent

  &:hover
    border-bottom: 2px solid $color-theme-mid
    opacity: 0.8
    transition: 0.4s

  &:active
    border-bottom: 2px solid $color-grey-light

  &--active
    border-bottom: 2px solid $color-theme-mid

button.button,
label.button
  padding: 8px
  border: 2px solid transparent
  border-radius: 4px

  &:hover
    border: 2px solid $color-theme-mid
    opacity: 0.8
    transition: 0.4s

  &:active
    border: 2px solid $color-grey

  &--active
    border: 2px solid $color-theme-mid

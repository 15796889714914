$width-mobile: 1200px

$color-background: #f5f5f5
$color-paper: #fefefe

$color-grey-light: #d3d3d3
$color-grey: #939393
$color-grey-dark: #4d4d4d

$color-theme-cold: #3f5efb
$color-theme-mid: #9e52b3
$color-theme-warm: #fc466b

$color-msg-info-back: #edd6f5
$color-msg-info-text: #4d4d4d
$color-msg-warn-back: #fddfde
$color-msg-warn-text: #b65350

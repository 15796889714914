.tooltip
  position: relative
  display: flex
  cursor: help

  & .tooltip__element
    align-self: center

    &:hover
      text-decoration: underline $color-theme-mid
      opacity: 0.8

  &:hover .tooltip__popup-hint
    visibility: visible

  &__popup-hint
    visibility: hidden
    position: absolute
    padding: 10px 12px
    border-radius: 3px
    transition: all .2s cubic-bezier(.4, 0, .2, 1)
    background-color: $color-paper
    color: $color-theme-mid
    font-style: italic
    z-index: 1
    +shadow()
    min-width: 150px
    font-size: 10px

    @media(min-width: $width-mobile)
      max-width: 300px
      font-size: 12px

    &--top
      bottom: 100%
      left: 0
      transform: translateY(-8px)

    &--right
      top: 50%
      left: 100%
      transform: translate(16px, -50%)

    &--bottom
      top: 100%
      left: 0
      transform: translateY(8px)

    &--left
      top: 50%
      right: 100%
      transform: translate(-16px, -50%)

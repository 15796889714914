.logo
  display: block
  margin: 10px 0
  font-family: 'RobertoSans-Thin', Tahoma, sans-serif
  text-transform: lowercase
  text-align: center
  text-decoration: none
  pointer-events: none
  cursor: default
  font-size: 60px
  +gradient-font()

  @media(min-width: $width-mobile)
    font-size: 100px

  &:hover,
  &:active
    opacity: 0.8

  &--link
    pointer-events: auto
    cursor: pointer

.categories

  &__empty-msg
    text-align: center

  &__list
    margin: 20px 0
    padding: 0
    list-style: none
    display: flex
    flex-wrap: wrap
    line-height: 16px

    & li
      margin: 0 10px 10px 0

      & .helpers-counter
        margin-left: 5px
        +gradient-font()

.search-methods

  &__list
    display: inline-block
    padding: 0
    margin: 0 0 16px 0

  &__sub-list
    list-style: circle
    margin: 16px 0

  & li
    margin-bottom: 10px
    line-height: 16px
    text-align: left

    & a.button
      display: inline

    &::marker
      color: $color-grey-dark

.anchor
  position: relative
  width: 18px

  & svg
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    fill: $color-grey
    width: 14px
    height: 14px

    @media(min-width: $width-mobile)
      width: 18px
      height: 18px

  &:hover
    opacity: 0.8

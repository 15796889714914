.contacts

  &__info
    margin: 0 0 10px 0

  &__list
    display: flex
    justify-content: center
    margin: 0 0 16px 0
    padding: 0
    list-style: none

    & li
      margin-right: 20px

      &:last-child
        margin-right: 0

      & a
        display: block
        position: relative
        width: 40px
        height: 40px
        padding: 8px

        & svg
          position: absolute
          top: 50%
          left: 50%
          transform: translate(-50%, -50%)
          fill: $color-theme-mid

        &:hover > svg
          fill: $color-theme-cold

        &:active > svg
          opacity: 0.8
